import AdminSideBar from "@/admin/common/sidebar/AdminSideBar"
import BrainSearchSidebar from "@/brain-search/internal/BrainSearchSidebar"
import ChatSidebar from "@/chat/sidebar/ChatSidebar"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import {
  isAdminConsolePath,
  isBrainSearchPath,
  isChatTabRoute,
  isEventsTabRoute,
  isProductsTabRoute,
} from "@/core/route/util/routeUtils"
import EventsSidebar from "@/occurrence/sidebar/EventsSidebar"
import CommunitySideBar from "@/organization/common/sidebar/CommunitySideBar"
import ProductsSidebar from "@/product/sidebar/ProductsSidebar"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import useSubscriptionStanding from "@utils/hook/useSubscriptionStanding"

/**
 * Returns the appropriate sidebar for the current page route
 */
export default function usePageSidebar() {
  const { brainSearch } = useFeatureFlags()

  const activeOrganization = useActiveOrganization()
  const subscriptionStanding = useSubscriptionStanding()
  const hasPlatformAccess = subscriptionStanding === "active"
  const isOrgAdmin = activeOrganization?.viewerIsOwnerOrAdmin
  const isProductManager =
    activeOrganization?.viewerMembership?.hasInstructorOrManagerRole
  const isGroupLead = activeOrganization?.viewerMembership?.hasGroupLeadRole
  const isOnAdmin = isAdminConsolePath(location.pathname)
  const isOnBrainSearch = isBrainSearchPath(location.pathname)
  if (isOnBrainSearch && brainSearch) return BrainSearchSidebar
  if (isOnAdmin && (isOrgAdmin || isProductManager || isGroupLead)) return AdminSideBar
  if (!hasPlatformAccess) return null
  if (isProductsTabRoute(location.pathname)) return ProductsSidebar
  if (isEventsTabRoute(location.pathname)) return EventsSidebar
  if (isChatTabRoute(location.pathname)) return ChatSidebar
  return CommunitySideBar
}
