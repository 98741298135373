import { CommunityAppBarProps } from "@/community/CommunityAppBar"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { isAdminConsolePath } from "@/core/route/util/routeUtils"
import GlobalNavButton from "@/main/page/global-nav/GlobalNavButton"
import { DiscoIcon } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { LocationDescriptorObject } from "history"

type AdminButtonProps = TestIDProps & Pick<CommunityAppBarProps, "variant">

export default function AdminButton(props: AdminButtonProps) {
  const { testid = "AdminButton", variant } = props
  const isActive = isAdminConsolePath(location.pathname)
  const activeOrganization = useActiveOrganization()
  const route = renderRoute()

  return (
    <GlobalNavButton
      testid={testid}
      to={route}
      tooltip={"Admin"}
      isActive={isActive}
      variant={variant}
    >
      <DiscoIcon icon={"flash"} active={isActive} />
    </GlobalNavButton>
  )

  function renderRoute(): string | LocationDescriptorObject<unknown> | undefined {
    if (activeOrganization?.viewerPermissions.has("organization_settings.manage"))
      return ROUTE_NAMES.ADMIN.DASHBOARD as string
    else if (activeOrganization?.viewerMembership?.hasInstructorOrManagerRole)
      return ROUTE_NAMES.ADMIN.INSIGHTS.PRODUCTS as string
    else if (activeOrganization?.viewerMembership?.hasGroupLeadRole)
      // TODO: Replace with the new default once available
      return `${ROUTE_NAMES.ADMIN.INSIGHTS.GROUPS}?tab=memberProgress`

    return undefined
  }
}
